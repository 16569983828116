<template>
  <Panel>
    <template #title><span>Change Password</span></template>
    <template #content>
      <el-form
        :model="PassForm"
        status-icon
        :rules="rules"
        ref="PassForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item
          label="Current Password"
          prop="oldpass"
          class="first-item"
        >
          <el-input
            type="password"
            v-model="PassForm.oldpass"
            autocomplete="off"
          >
            ></el-input
          >
        </el-form-item>
        <el-form-item label="New Password" prop="newpass">
          <el-input
            type="password"
            v-model="PassForm.newpass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="Reconfirm Password" prop="checkpass">
          <el-input
            type="password"
            v-model="PassForm.checkpass"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            @click="submitForm('PassForm')"
            :disabled="flag"
            >Submit</el-button
          >
          <el-button @click="resetForm('PassForm')">Reset</el-button>
        </el-form-item>
      </el-form>
    </template>
  </Panel>
</template>

<script>
import {
  validatePwd,
  validateCheckpass,
  validateNewpass
} from '@/utils/validate.js'
import { changpwd } from '@/api/user'
export default {
  data() {
    return {
      flag: false,
      PassForm: {
        oldpass: '',
        newpass: '',
        checkpass: ''
      },
      rules: {
        oldpass: [{ validator: validatePwd, trigger: 'blur' }],
        newpass: [{ validator: validateNewpass(this), trigger: 'blur' }],
        checkpass: [{ validator: validateCheckpass(this), trigger: 'blur' }]
      },
      // 保存旧密码的状态
      oldpassStatus: false
    }
  },
  methods: {
    submitForm(formName) {
      if (this.flag) {
        return
      }
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          this.flag = true
          const data = await changpwd({
            current_password: this.PassForm.oldpass,
            new_password: this.PassForm.newpass
          })
          // console.log(data)
          if (data.status === 200) {
            this.$message({
              message: 'Edit successful, please login again.',
              type: 'success'
            })
            this.$router.push('/login')
            setTimeout(() => {
              this.flag = false
            }, 2000)
          } else {
            this.$message({
              message: data.data.toperr,
              type: 'error'
            })
            setTimeout(() => {
              this.flag = false
            }, 2000)
          }
        }
      })
    },
    // 重置
    resetForm(formName) {
      this.$refs[formName].resetFields()
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.el-form-item__label {
  width: 200px !important;
}
/deep/.el-form-item__content {
  margin-left: 200px !important;
}
.first-item {
  margin-top: 20px;
}
.el-form-item {
  margin-bottom: 40px;
}
</style>
